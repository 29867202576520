import React from "react";

import Latest from "./Latest";

import MostViewed from "./MostViewed";

const Main = () => {
  // const { loading, podcasts } = useSelector((state) => state.podcastsList);
  return (
    <div className="min-h-screen   flex  items-center flex-col py-4">
      {/* <div className="justify-items-center gap-y-[4rem] mt-11 grid grid-cols-12">
        <Podcast />
        <Podcast />
        <Podcast />
        <Podcast />
      </div> */}
      <Latest />

      {/* <MostLiked /> */}
      <MostViewed />
    </div>
  );
};

export default Main;
