import axios from "axios";
import URL from "../api";

import {
  CLOSE_SUBSCRIBTION_MODAL,
  CLOSE_VERIFY_MODAL,
  OPEN_WARNING,
  SUBSCRIBE_CANCEL_FAIL,
  SUBSCRIBE_CANCEL_REQUEST,
  SUBSCRIBE_CANCEL_SUCCESS,
  SUBSCRIBE_COMPLETE_FAIL,
  SUBSCRIBE_COMPLETE_REQUEST,
  SUBSCRIBE_COMPLETE_SUCCESS,
  SUBSCRIBE_INITIATE_FAIL,
  SUBSCRIBE_INITIATE_REQUEST,
  SUBSCRIBE_INITIATE_SUCCESS,
  SUBSCRIBE_STATUS_FAIL,
  SUBSCRIBE_STATUS_REQUEST,
  SUBSCRIBE_STATUS_SUCCESS,
} from "../types";
import { login, logout } from "./authActions";

export const initiateSubscribe = (msisdn) => async (dispatch) => {
  try {
    dispatch({ type: SUBSCRIBE_INITIATE_REQUEST });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    const { data } = await axios.post(
      URL + "/subscribtion/initiate",
      { msisdn },
      config
    );

    localStorage.setItem("msisdn", JSON.stringify(msisdn));

    dispatch({ type: SUBSCRIBE_INITIATE_SUCCESS, payload: data });
    dispatch({ type: CLOSE_SUBSCRIBTION_MODAL });

    window.location.href = data.url;
  } catch (error) {
    dispatch({
      type: SUBSCRIBE_INITIATE_FAIL,
      payload: error.response && error.response.data,
    });
    console.log(error);
  }
};
export const completeSubscribe = (otp) => async (dispatch) => {
  try {
    dispatch({ type: SUBSCRIBE_COMPLETE_REQUEST });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    const subscribe_request_id = localStorage.getItem("subscribe_id");
    const msisdn = localStorage.getItem("msisdn");
    const { data } = await axios.post(
      URL + "/subscribtion/complete",
      { otp, subscribe_request_id, msisdn },
      config
    );
    // if (data.success === false) throw Error(data.message);
    localStorage.removeItem("subscribe_id");
    localStorage.setItem("subscriberInfo", JSON.stringify(data));

    dispatch({ type: SUBSCRIBE_COMPLETE_SUCCESS, payload: data });
    dispatch(checkStatus());
    dispatch({ type: CLOSE_VERIFY_MODAL });
  } catch (error) {
    // dispatch({type: CLOSE_VERIFY_MODAL})
    let message;

    if (error.response && error.response.data.error_code === 117) {
      message = "فشل في الدفع، سوف يتم محاولة تفعيل اشتراكك لاحقا";
      dispatch({ type: CLOSE_VERIFY_MODAL });
      dispatch({ type: OPEN_WARNING, payload: message });
    }
    dispatch({
      type: SUBSCRIBE_COMPLETE_FAIL,
      payload: error.response && error.response.data,
    });
    dispatch(checkStatus());
  }
};

export const checkStatus = () => async (dispatch) => {
  try {
    dispatch({ type: SUBSCRIBE_STATUS_REQUEST });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    const msisdn = localStorage.getItem("msisdn");

    const { data } = await axios.get(
      URL + `/zoalcast/check_subscription/${msisdn}`
    );

    localStorage.setItem("subscriberInfo", JSON.stringify(data));

    dispatch({ type: SUBSCRIBE_STATUS_SUCCESS, payload: data });
    // dispatch(login({ phone: msisdn }));
  } catch (error) {
    console.log(error);
    dispatch(logout())
    dispatch({
      type: SUBSCRIBE_STATUS_FAIL,
      payload: error.response && error.response.data,
    });

    // dispatch(logout());
    console.log(error);
  }
};
export const cancelSubscription = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SUBSCRIBE_CANCEL_REQUEST });

    const {
      loginUser: { user },
    } = getState();

    const config = {
      headers: {
        authorization: "Bearer " + user?.token,
      },
    };
    const msisdn = localStorage.getItem("msisdn");
    const { data } = await axios.post(
      URL + "/zoalcast/unsubscribe",
      { phone: "2491" + msisdn },
      config
    );
    dispatch({ type: SUBSCRIBE_CANCEL_SUCCESS, payload: data });
    dispatch(logout());
  } catch (error) {
    dispatch(logout())
    console.log(error);
    dispatch({
      type: SUBSCRIBE_CANCEL_FAIL,
      payload: error.response && error.response.data,
    });

    console.log(error);
  }
};
