import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  CircularProgress,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { logout } from "../redux/actions/authActions";
import { getCategories } from "../redux/actions/categoryActions";
import { cancelSubscription } from "../redux/actions/subscribtionActions";
// import { logout } from "../redux/actions/authActions";
import { OPEN_AUTH_MODAL, OPEN_CANCEL_MODAL } from "../redux/types";

const Sidebar = ({ open, setOpen }) => {
  const [expand, setExpand] = useState(false);
  const { user } = useSelector((state) => state.loginUser);
  const { categories, loading } = useSelector((state) => state.categoriesList);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  const colors = {
    0: "bg-[#01954B]",

    2: "bg-[#565759]",
    1: "bg-[#4EC1E0]",
    3: "bg-[#E11A8E]",
  };
  return (
    <Drawer
      open={open}
      anchor="right"
      classes={{ paper: "mt-[4rem] w-48 sm:w-64  md:w-72" }}
      variant="persistent"
      onClose={() => setOpen(false)}
    >
      <List className="md:ml-4  ">
        <ListItem button>
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive
                ? "text-blue-600 !w-full text-right child:w-full"
                : "w-full text-right"
            }
          >
            <ListItemText
              primary="الرئيسية"
              className="w-fit child:w-fit text-xs"
            />
          </NavLink>
        </ListItem>
        <ListItem button className="flex w-fit child:w-fit cursor-pointer ">
          <NavLink
            to="/explore/page=1"
            className={({ isActive }) =>
              isActive ? "text-blue-600 !w-full" : "w-full text-right"
            }
          >
            <ListItemText
              primary="تصفح البودكاست"
              className="w-fit child:w-fit text-xs"
            />
          </NavLink>
        </ListItem>
        <ListItem button className="flex w-fit child:w-fit">
          {user ? (
            <NavLink
              className={({ isActive }) =>
                isActive ? "text-blue-600 !w-full" : "w-full text-right"
              }
              to="/likes"
            >
              {/* <ListItemIcon className="w-fit child:!w-fit">
            <FavoriteBorderSharp />
          </ListItemIcon> */}
              <ListItemText
                primary="الإعجابات"
                className="w-fit child:w-fit text-xs"
              />
            </NavLink>
          ) : (
            <ListItemText
              primary="الإعجابات"
              onClick={() => {
                setOpen(false);
                dispatch({ type: OPEN_AUTH_MODAL });
              }}
              className="w-fit child:w-fit  text-xs "
            />
          )}
          {/* <ListItemIcon className="w-fit child:!w-fit">
            <FavoriteBorderSharp />
          </ListItemIcon> */}
        </ListItem>
        <ListItem button className="flex w-fit child:w-fit">
          {user ? (
            <NavLink
              className={({ isActive }) =>
                isActive ? "text-indigo-600 !w-full" : "w-full text-right"
              }
              to="/recent"
            >
              {/* <ListItemIcon className="w-fit child:!w-fit">
            <FavoriteBorderSharp />
          </ListItemIcon> */}
              <ListItemText
                primary="تم تشغيلها مؤخرا"
                className="w-fit child:w-fit text-xs"
              />
            </NavLink>
          ) : (
            <ListItemText
              onClick={() => {
                setOpen(false);
                dispatch({ type: OPEN_AUTH_MODAL });
              }}
              primary="تم تشغيلها مؤخرا"
              className="w-fit child:w-fit  text-xs "
            />
          )}
          {/* <ListItemIcon className="w-fit child:!w-fit">
            <FavoriteBorderSharp />
          </ListItemIcon> */}
        </ListItem>

        <ListItem
          button
          onClick={() => setExpand(!expand)}
          className="flex !justify-between child:w-fit"
        >
          <ListItemText primary="الفئات" className="w-fit child:w-fit" />
          <ListItemIcon>
            {expand ? <ExpandLess /> : <ExpandMore />}
          </ListItemIcon>
        </ListItem>

        <List
          className={`!pr-[1rem] ${
            !expand && "hidden"
          } transition-all duration-100`}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            categories?.map((category, i) => (
              <ListItem button key={category.id}>
                <NavLink
                  state={{ id: category.id, background: colors[i] }}
                  to={`/categories/${category.name}/page=1`}
                  className={({ isActive }) =>
                    isActive
                      ? "text-blue-600 !w-full text-right"
                      : "w-full text-right"
                  }
                >
                  <ListItemText
                    primary={`${category.name}`}
                    className="w-fit child:w-fit text-xs"
                  />
                </NavLink>
              </ListItem>
            ))
          )}
        </List>

        {user && (
          <ListItem
            onClick={() => {
              dispatch({ type: OPEN_CANCEL_MODAL });
              setOpen(false);
            }}
            button
          >
            <ListItemText
              primary="إلغاء الاشتراك"
              className="w-full text-right text-red-600 child:!text-xs"
            />
          </ListItem>
        )}
      </List>
    </Drawer>
  );
};

export default Sidebar;
