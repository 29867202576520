import React from "react";

const Hero = () => {
  return (
    <div
      style={
        {
          // backgroundImage: "url('../data/Zool_Cast_Banner.jpg')",
        }
      }
      className=" rounded relative h-72  "
    >
      <img
        src="/images/Zool_Cast_Banner.jpg"
        alt=""
        className="absolute z-0 top-0 w-full opacity-95 h-full "
        width="100%"
        height="100%"
      />
      <div className="absolute z-20 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] text-white text-center text-3xl  w-[80%] ">
        <h2 className="text-4xl">زول كاست</h2>
        <p>مرحبا بك في منصة زول كاست...</p>
      </div>
    </div>
  );
};

export default Hero;
