import React, { useState } from "react";

import Header from "../components/Header";

import WavePlayer from "../components/WavePlayer";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { ADD_TO_PLAYLIST, GET_PLAYLIST, RESET_ADDED } from "../redux/types";
import {
  IconButton,
  Pagination,
  Slide,
  Snackbar,
  ThemeProvider,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import AuthModal from "../components/AuthModal";
import {
  getCategoryPodcasts,
  getPodcastDetails,
  incPodastViews,
  LikePodcast,
  UNLikePodcast,
} from "../redux/actions/podcastActions";

import theme from "../config/theme";

import {
  FavoriteBorderOutlined,
  FavoriteSharp,
  PlaylistAdd,
  Share,
  Visibility,
} from "@mui/icons-material";
import PodcastLoader from "../components/PodcastLoader";
import { useRef } from "react";
import CancelModal from "../components/CancelModal";
import PodcastPlayer from "../components/PodcastPlayer";
import { FacebookIcon, FacebookShareButton } from "react-share";
import ShareModal from "../components/ShareModal";

const PlaylistPage = () => {
  const { id } = useParams();

  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);

  //podcast state
  // const { podcast } = useSelector((state) => state.podcastDetails);

  //playlist state
  const { playlist, added } = useSelector((state) => state.playlist);

  const { user } = useSelector((state) => state.loginUser);
  //auth state
  const { success } = useSelector((state) => state.podcastViews);

  const { podcast } = useSelector((state) => state.podcastDetails);
  // categories
  const { categories } = useSelector((state) => state.categoriesList);
  // category podcasts state
  const { loading, podcasts, pages } = useSelector(
    (state) => state.categoryPodcasts
  );
  // let podcast = podcastsRaw.find((x) => x.id === id);

  // let podcasts = podcastsRaw.filter((x) => x.category === podcast.category);
  const categoryid = podcast?.category_id;
  const category = categories?.find((x) => x.id === categoryid);

  const scrollRef = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: GET_PLAYLIST });
    dispatch(getPodcastDetails(id));
  }, [dispatch, id, success, podcast?.id, user?.token]);

  const navigate = useNavigate();
  useEffect(() => {
    if (isNaN(id)) return navigate("/");
    dispatch(incPodastViews(id));
  }, [id, dispatch, navigate]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (scrollRef.current) scrollRef.current.scrollIntoView();
    if (categoryid) dispatch(getCategoryPodcasts(categoryid, page));
  }, [page, categoryid, dispatch]);

  return (
    <div>
      {/* <button onClick={handleClick}>click</button>
      <div ref={waveformRef}></div> */}

      <Header />
      <Slide
        in={true}
        direction="left"
        timeout={1000}
        unmountOnExit
        //   addEndListener={() => setTranstion(false)}
      >
        <main
          id="main"
          className="my-12 mx-3 xs:mx-12 md:mx-32 flex flex-col lg:flex-row items-start"
        >
          <div className="flex flex-col items-center justify-center flex-[.6]">
            <div className="flex justify-center flex-col items-center  lg:flex-row ">
              <div className="md:ml-2 w-full md:w-[80%] md:flex-[.4] lg:flex-[.33] ">
                <img
                  // src={`${URL}/${podcast?.poster}`}
                  src={`${podcast?.image}`}
                  // src="https://api.zoalcast.com/podcasts/ZoalCast/images/62f146af1a036.jpg"
                  alt={podcast?.name}
                  className="block w-full rounded shadow"
                />{" "}
              </div>
              <div className=" mt-6 md:flex-[.3] md:mr-4" ref={scrollRef}>
                <div className="bg-[#F38E1C] hidden   text-white rounded-lg font-bold shadow-md px-2 py-1 mb-4">
                  {category?.name}
                </div>
                <h2 className="text-2xl mb-2">{podcast?.name}</h2>
                <p className="w-full">{podcast?.description}</p>
                <strong className="text-xs text-gray-600 flex-1">
                  {podcast?.created_at}
                </strong>

                <div className="flex items-center">
                  <IconButton
                    onClick={() =>
                      dispatch({ type: ADD_TO_PLAYLIST, payload: podcast })
                    }
                    className="child:!text-[1rem] md:child:!text-[1.4rem] "
                  >
                    <PlaylistAdd />
                  </IconButton>
                  <IconButton className="child:!text-[1rem] md:child:!text-[1.4rem]">
                    {podcast?.liked ? (
                      <FavoriteSharp
                        onClick={() => dispatch(UNLikePodcast(podcast?.id))}
                        className="!text-red-600"
                      />
                    ) : (
                      <FavoriteBorderOutlined
                        onClick={() => dispatch(LikePodcast(podcast?.id))}
                      />
                    )}{" "}
                  </IconButton>
                  <span className="text-xs text-gray-600">
                    {podcast?.likes}
                  </span>

                  <IconButton onClick={() => setOpen(true)}>
                    <Share />
                  </IconButton>
                  {/* <FacebookShareButton className="mr-4" quote={`<strong>${podcast?.name}</strong> - ${podcast?.description}`}
          url={window.location.href}>
                    <FacebookIcon borderRadius={10} size="30"/>
                  </FacebookShareButton> */}
                </div>
              </div>
            </div>
            {!user ? (
              <PodcastPlayer podcast={podcast} />
            ) : (
              // <WavePlayer />
              <p
                className="text-xl text-center mt-14 bg-slate-100 w-[80%] text-black p-6 "
                id="scroll"
              >
                الرجاء الإشتراك للإستماع للمحتوى
                <a
                  href="http://sudanipay.com/subscription/zoalcast/zoalcast/"
                  className="mr-2 text-[#2563EB]"
                >
                  من هنا
                </a>{" "}
              </p>
            )}
            {true && (
              <>
                {" "}
                <h2 className="my-3">قائمة التشغيل</h2>
                {playlist.length === 0 ? (
                  <p className="text-center text-slate-500 p-4">
                    لا يوجد شئ في القائمة
                  </p>
                ) : (
                  <ul className="child:border w-full sm:w-[90%] md:w-[90%] lg:w-[70%] xl:w-[50%] 2xl:w-[40%]  child:border-collapse max-h-[10rem] overflow-auto">
                    {playlist.map((x) => (
                      <li className="flex justify-between items-center cursor-pointer border hover:bg-slate-100 p-2 w-full ">
                        <NavLink
                          className={({ isActive }) =>
                            isActive
                              ? "text-[#F38E1C] flex justify-between items-center w-full"
                              : "flex justify-between items-center w-full "
                          }
                          key={x.id}
                          to={`/podcasts/${x.id}`}
                        >
                          <div className="flex items-center">
                            <div className="w-12 ml-4">
                              <img
                                src={x.image}
                                className="w-full rounded"
                                alt=""
                              />
                            </div>
                            <p className=" overme">{x.name}</p>
                          </div>
                          <span className="block">
                            {x.duration.split(".")[0]}
                          </span>
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                )}
              </>
            )}
          </div>
          {/* category podcasts */}

          <section className="mt-4 flex-[.4]">
            <h2 className="  p-4 !text-3xl  pr-12  border-b-2 w-full">
              {category?.name}
            </h2>
            <div className="min-h-full">
              {loading ? (
                <PodcastLoader />
              ) : (
                <div className=" lg:min-h-full  gap-y-[4rem] mt-11 grid grid-cols-12">
                  {podcasts?.map((podcast) => (
                    <Link
                      to={`/podcasts/${podcast.id}#main`}
                      key={podcast.id}
                      className="group col-span-12 md:col-span-4 lg:col-span-12 flex w-full"
                    >
                      <div className=" rounded relative flex-[.3] md:flex-[.4] group-hover:grayscale-[50%] transition duration-300  cursor-pointer  w-[10rem] md:w-[12rem]">
                        {/* poster */}

                        <img
                          // src={`${URL}/${podcast.poster}`}
                          src={`${podcast.image}`}
                          alt={`${podcast.name}`}
                          loading="lazy"
                          className="w-full rounded-lg shadow h-full block"
                        />

                        <div className="flex absolute top-0 h-full justify-end items-center    rounded-lg  left-0 w-full group-hover:bg-[#343434c9]">
                          {/* <div className="absolute flex-col group-hover:translate-x-6 transition duration-300 hidden">
                            <IconButton className="w-fit">
                              <FavoriteBorderOutlined
                                classes={{
                                  root: "text-white hover:text-slate-300  hover:text-[25px]",
                                }}
                              />
                            </IconButton>
                            <IconButton className="w-fit">
                              <PlayCircleOutlined
                                classes={{
                                  root: "text-white hover:text-slate-300 hover:text-[25px]",
                                }}
                              />
                            </IconButton>
                            <IconButton className="w-fit">
                              <PlaylistAdd
                                classes={{
                                  root: "text-white hover:text-slate-300 hover:text-[25px]",
                                }}
                              />
                            </IconButton>
                          </div> */}
                        </div>
                      </div>
                      <div className="flex flex-col min-h-[5rem] flex-[.6]  mr-2 pr-2">
                        <div className="flex-1 h-full">
                          <h2 className="group-hover:text-md transition duration-300 cursor-pointer text-[.7rem] font-bold md:text-sm ">
                            {podcast.name}
                          </h2>
                          <p className="overme !w-48 lg:w-96 md:w-20">
                            {podcast.description}
                          </p>
                          <div className="flex justify-between items-center mt-2">
                            <p className="text-xs text-gray-600  font-bold ">
                              {podcast?.duration.split(".")[0]} دقيقة
                            </p>
                            <div className="child:block">
                              <span className="text-gray-600 text-xs font-bold">
                                {/* {dayjs(podcast?.createdAt).locale(arSa).fromNow()}
                                 */}
                                {/* {new Date().toLocaleDateString()} */}
                                {podcast.created_at}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="pr-2 mt-1 flex justify-between items-center ">
                          <div className="">
                            <span className="text-[.7rem]">
                              <FavoriteSharp className="text-red-600 !text-[.8rem]" />{" "}
                              {podcast.likes}{" "}
                            </span>

                            <span className="text-[.7rem]">
                              <Visibility className="text-slate-400 !text-[.5reml]" />{" "}
                              {podcast.views}{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              )}
            </div>
            <ThemeProvider theme={theme}>
              <div className="col-span-12 grid place-items-center my-6">
                {pages > 1 && (
                  <Pagination
                    color="primary"
                    count={pages}
                    page={page}
                    onChange={handlePageChange}
                    shape="rounded"
                    variant="outlined"
                  />
                )}
              </div>
            </ThemeProvider>
          </section>
        </main>
      </Slide>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        message="تمت الإضافة لقائمة التشغيل"
        open={added}
        onClose={() => dispatch({ type: RESET_ADDED })}
      />
      {/* modals */}
      <AuthModal />
      <ShareModal open={open} setOpen={setOpen} podcast={podcast} />
      <CancelModal />
    </div>
  );
};

export default PlaylistPage;
