import axios from "axios";
import URL, { portalId } from "../api";
import {
  AUTHENTICATE_USER_FAIL,
  AUTHENTICATE_USER_REQUEST,
  AUTHENTICATE_USER_SUCCESS,
  LOGOUT_USER,
  SUBSCRIBE_USER_FAIL,
  SUBSCRIBE_USER_REQUEST,
  SUBSCRIBE_USER_SUCCESS,
} from "../types";
import { cancelSubscription } from "./subscribtionActions";

export const subscribe = (user) => async (dispatch) => {
  try {
    dispatch({ type: SUBSCRIBE_USER_REQUEST });

    const { data } = await axios.post(
      URL + "/user/subscribe",
      { ...user, portal_id: portalId },
      {
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    dispatch({ type: SUBSCRIBE_USER_SUCCESS, payload: data });
    dispatch({ type: AUTHENTICATE_USER_SUCCESS, payload: data });
    localStorage.setItem("user", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: SUBSCRIBE_USER_FAIL,
      payload: error.response.data ? error.response.data.errors : error.message,
    });
    console.log(error);
  }
};

export const login = (user) => async (dispatch) => {
  try {
    dispatch({ type: AUTHENTICATE_USER_REQUEST });
    console.log({ user });
    localStorage.setItem("msisdn", user?.phone);
    const { data } = await axios.post(
      URL + "/user/login",
      { phone: "01" + user?.phone, portal_id: portalId },
      {
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    dispatch({ type: AUTHENTICATE_USER_SUCCESS, payload: data });
    localStorage.setItem("user", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: AUTHENTICATE_USER_FAIL,
      payload: error.response.data ? error.response.data.errors : error.message,
    });
    console.log(error);
  }
};

export const logout = () => (dispatch) => {
  dispatch({ type: LOGOUT_USER });
  localStorage.removeItem("user");
};
