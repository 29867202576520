import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AUTHENTICATE_USER, CLOSE_AUTH_MODAL } from "../redux/types";

const AuthModal = () => {
  // const { open } = useSelector((state) => state.AuthModal);
  const { open } = useSelector((state) => state.authModal);

  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch({ type: CLOSE_AUTH_MODAL });
  };

  const handleAuth = () => {
    dispatch({ type: AUTHENTICATE_USER });
    dispatch({ type: CLOSE_AUTH_MODAL });
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>مرحبا بك </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-slide-description"
          className="text-black"
        >
          الرجاء الاشتراك أو تسجيل الدخول للاستفادة من كافة المحتوى
        </DialogContentText>
      </DialogContent>
      <DialogActions className="!flex !justify-between">
        <div>
          <Link
            to="/login"
            onClick={handleClose}
            className="bg-blue-600 mx-2 w-16 text-xs   text-white rounded py-2 px-2"
          >
            اشتراك
          </Link>
          <Link
            to="/login"
            onClick={handleClose}
            className="bg-black mx-2 text-xs   w-16 text-white rounded py-2 px-2"
          >
            الدخول
          </Link>
        </div>
        <button
          onClick={handleClose}
          className="bg-slate-400 mx-2 text-xs   w-16 text-white rounded py-2 px-2"
        >
          إلغاء
        </button>
      </DialogActions>
    </Dialog>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default AuthModal;
