import { FavoriteSharp, Visibility } from "@mui/icons-material";
import { IconButton, Zoom } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const Podcast = ({ podcast, handlePlay, width, height }) => {
  return (
    <Zoom
      in={true}
      timeout={3000}
      className={`col-span-6 sm:col-span-6 md:col-span-4 lg:col-span-3 ${width} `}
    >
      <Link to={`/podcasts/${podcast.id}`}>
        <div
          onClick={handlePlay}
          className="group col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 max-w-[100%] sm:max-w-[100%] relative mx-3 sm:mx-6  md:mx-14"
        >
          <div className=" rounded relative group-hover:grayscale-[10%] transition duration-300 w-full cursor-pointer h-[10rem] lg:h-[15rem]">
            {/* poster */}
            <img
              // src={`/iSOUND_Temp.jpg`}
              src={`${podcast?.image}`}
              // src="/mSound_Templet.jpg"
              alt={`${podcast?.name}`}
              className="w-full rounded shadow h-full block"
            />
          </div>
          <div className="flex flex-col min-h-[5rem]  mt-2 pr-2" dir="rtl">
            <div className="flex-1 h-full">
              <p className="text-[1rem] whitespace-normal truncate overme md:w-[200px]">
                {podcast?.name}
              </p>
              {/* podcast duration and upload date */}
              <div className="flex justify-between items-center mt-2">
                <p className="text-xs text-gray-500  font-bold ">
                  {podcast?.duration.split(".")[0]} دقيقة
                </p>
                <div className="child:block">
                  <span className="text-gray-500 text-xs">
                    {podcast?.created_at}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <IconButton>
                <FavoriteSharp className="text-red-400" />
                <span className="text-sm h-full block mt-1 pr-1 ">
                  {podcast?.likes}
                </span>
              </IconButton>

              <IconButton>
                <Visibility />
                <span className="text-sm h-full block mt-1 pr-1">
                  {podcast?.views + 26}
                </span>
              </IconButton>
            </div>
          </div>
        </div>
      </Link>
    </Zoom>
  );
};

export default Podcast;
