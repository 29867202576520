import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const PodcastLoader = () => {
  return (
    <SkeletonTheme
      baseColor="#202020"
      highlightColor="#444"
      className=" col-span-12 md:col-span-4 lg:col-span-12 flex w-full"
    >
      <div className=" rounded h-[5rem] w-[10rem] relative flex-[.3] md:flex-[.4]  transition duration-300  cursor-pointer   md:w-[12rem]">
        {/* poster */}
        <Skeleton
          height="100%"
          containerClassName="bg-gray-200  "
          width="100%"
        />
        <div className="flex absolute top-0 h-full justify-end items-center    rounded-lg  left-0 w-full group-hover:bg-[#343434c9]"></div>
      </div>
      <div className="flex flex-col min-h-[5rem] flex-[.6]  mr-2 pr-2">
        <div className="flex-1 h-full">
          <h2 className="group-hover:text-md transition duration-300 cursor-pointer text-[.7rem] font-bold md:text-sm ">
            <Skeleton />
          </h2>
          <p>
            {" "}
            <Skeleton count={2} />
          </p>
          <div className="flex justify-between items-center mt-2">
            <p className="text-xs text-gray-600  font-bold ">
              <Skeleton />
            </p>
            <div className="child:block">
              <span className="text-gray-600 text-xs font-bold">
                <Skeleton />
              </span>
            </div>
          </div>
        </div>
        <div className="pr-2 mt-1 flex justify-between items-center ">
          <div className="">
            <span className="text-[.7rem]">
              {/* <FavoriteSharp className="text-red-600 !text-[.8rem]" />{" "} */}
              <Skeleton />
            </span>

            <span className="text-[.7rem]">
              {/* <Visibility className="text-slate-400 !text-[.5reml]" />{" "} */}
              <Skeleton />
            </span>
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default PodcastLoader;
