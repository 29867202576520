import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { OPEN_AUTH_MODAL } from "../redux/types";

const ProtectedRoute = ({ children }) => {
  const { user } = useSelector((state) => state.loginUser);
  const dispatch = useDispatch();
  console.log({ user });
  if (user) return children;
  // dispatch({ type: OPEN_AUTH_MODAL });
  return <Navigate to="/" />;
};

export default ProtectedRoute;
