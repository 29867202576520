import axios from "axios";
import URL from "../api";

import {
  GET_CATEGORIES_FAIL,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
} from "../types";

export const getCategories = () => async (dispatch) => {
  try {
    dispatch({ type: GET_CATEGORIES_REQUEST });
    const { data } = await axios.get(URL + "/portal/3/categories");
    dispatch({ type: GET_CATEGORIES_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: GET_CATEGORIES_FAIL, payload: error.message });
    console.log(error);
  }
};
