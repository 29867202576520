import React from "react";
import LoaderUnit from "./LoaderUnit";

const SliderLoader = () => {
  return (
    <div className="flex items-center justify-between child:hidden  md:child:block  w-full child:mx-10">
      <LoaderUnit show />
      <LoaderUnit />
      <LoaderUnit />
    </div>
  );
};

export default SliderLoader;
