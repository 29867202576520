import { Skeleton } from "@mui/material";
import React from "react";

const LoaderUnit = ({ show }) => {
  return (
    <div
      className={`col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 w-[80%] ${
        show && "!block"
      }`}
    >
      <Skeleton variant="rectangular" height={200} width={200} />
      <Skeleton variant="text" width={200} />
      <Skeleton variant="text" width={200} />
    </div>
  );
};

export default LoaderUnit;
