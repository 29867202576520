import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Nav = () => {
  const { categories, loading } = useSelector((state) => state.categoriesList);
  const colors = {
    0: "bg-[#01954B]",

    2: "bg-[#565759]",
    1: "bg-[#4EC1E0]",
    3: "bg-[#E11A8E]",
  };
  return (
    <div className="grid place-items-center text-white flex-wrap mt-10  mx-2 ">
      <div className="grid grid-cols-12 gap-[1rem] w-[80%] md:w-[60%] place-items-center text-white flex-wrap  mx-2 grid-rows-2">
        {categories?.map((x, i) => (
          <Link
            state={{ id: x.id, background: colors[i] }}
            key={x.id}
            to={`/categories/${x.name}/page=1`}
            className={`text-xs p-2 col-span-6 md:py-6 w-full h-full text-center md:text-xl font-bold rounded-md  md:rounded-full shadow ${colors[i]}`}
          >
            {x.name}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Nav;
