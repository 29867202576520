import { combineReducers } from "redux";
import {
  authModalReducer,
  authReducer,
  cancelModalReducer,
  loginUserReducer,
  subscribeUserReducer,
} from "./authReducers";
import {
  playlistReducer,
  podcastLikeReducer,
  recentReducer,
  podcastListReducer,
  podcastDetailsReducer,
  podcastLatestReducer,
  podcastMostLikedReducer,
  podcastMostViewedReducer,
  categoryPodcastsReducer,
  podcastViewsReducer,
  likedPodcastsReducer,
} from "./podcastReducers";

import { categoryListReducer } from "./CategoryReducers";
import { subscribeStatusReducer } from "./subscribtionReducer";

export default combineReducers({
  podcastLike: podcastLikeReducer,
  playlist: playlistReducer,
  recent: recentReducer,
  auth: authReducer,
  authModal: authModalReducer,
  cancelModal: cancelModalReducer,
  subscribeStatus: subscribeStatusReducer,
  loginUser: loginUserReducer,
  podcastsList: podcastListReducer,
  podcastDetails: podcastDetailsReducer,
  likedPodcasts: likedPodcastsReducer,
  categoryPodcasts: categoryPodcastsReducer,
  // podcastDetails: podcastDetailsReducer,
  podcastLatest: podcastLatestReducer,
  // videoReview: videoReviewReducer,
  podcastMostLiked: podcastMostLikedReducer,
  podcastMostViewed: podcastMostViewedReducer,
  podcastViews: podcastViewsReducer,
  categoriesList: categoryListReducer,

  // categoryDetails: categoryDetailsReducer,
});
