import { Phone } from "@mui/icons-material";
import { Slide } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { login, subscribe } from "../redux/actions/authActions";

const Subscribe = () => {
  const [phone, setPhone] = useState("");

  const { user, error } = useSelector((state) => state.subscribeUser);
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(subscribe({ phone }));
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (user) navigate("/");
  }, [user]);
  return (
    <Slide in={true} timeout={1000} unmountOnExit>
      <div className="flex justify-center items-center bg-slate-50 min-h-screen">
        <form className="border shadow-lg p-4 min-w-md" onSubmit={handleSubmit}>
          <h2 className="mb-4 text-center">الاشتراك في زول كاست</h2>
          {error && <p className="text-red-600 mb-4">{error}</p>}
          <div className="mb-3 border flex focus-within:border-blue-200  focus-within:border-2  h-8">
            <Phone className="bg-slate-100 border ml-3 p-1 h-full !text-3xl" />
            <input
              type="tel"
              placeholder="249xxxxxxxxx"
              className="border-none outline-none"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <button className="bg-blue-400 mt-4 text-white border rounded shadow p-1 w-full">
            {" "}
            اشترك الان
          </button>
          <Link
            className="mt-2 text-center text-xs w-full text-blue-400 block font-bold"
            to="/login"
          >
            تسجيل الدخول
          </Link>
        </form>
      </div>
    </Slide>
  );
};

export default Subscribe;
