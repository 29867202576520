import { Pagination, Slide } from "@mui/material";
import { ThemeProvider } from "@mui/styles";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CancelModal from "../components/CancelModal";
import Header from "../components/Header";
import Loader from "../components/Loader";
import Podcast from "../components/Podcast";
import theme from "../config/theme";
import podcasts from "../data/podcasts";
import { getLikedPodcasts } from "../redux/actions/podcastActions";

const LikesPage = () => {
  const { user } = useSelector((state) => state.loginUser);

  const { podcasts, loading, pages } = useSelector(
    (state) => state.likedPodcasts
  );
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    if (!user) navigate("/");
    dispatch(getLikedPodcasts(page));
  }, [user, navigate]);
  return (
    <Slide
      in={true}
      timeout={1000}
      unmountOnExit
      //   addEndListener={() => setTranstion(false)}
    >
      <div>
        <Header />

        <h2 variant="h2" className="text-2xl  p-6  border-b-2 w-fit">
          قائمة إعجاباتك
        </h2>
        <main className="min-h-screen">
          <div className="justify-items-center gap-y-[4rem] mt-11 grid grid-cols-12">
            {loading ? (
              <Loader />
            ) : (
              podcasts?.map((x) => <Podcast podcast={x} showLikes showViews />)
            )}
          </div>
          <ThemeProvider theme={theme}>
            <div className="col-span-12 grid place-items-center my-6">
              {pages > 1 && (
                <Pagination
                  color="primary"
                  count={pages}
                  page={page}
                  onChange={handlePageChange}
                  shape="rounded"
                  variant="outlined"
                />
              )}
            </div>
          </ThemeProvider>
        </main>
        <CancelModal />
      </div>
    </Slide>
  );
};

export default LikesPage;
