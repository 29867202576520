import React, { useEffect, useState } from "react";
import { Box, Button, Paper, TextField, Typography, Zoom } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { login } from "../redux/actions/authActions";

const Login = () => {
  const [phone, setPhone] = useState("");

  const { user, error } = useSelector((state) => state.loginUser);
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(login({ phone: formatPhone(phone) }));
  };

  function formatPhone(phone) {
    if (phone.startsWith("249")) {
      return phone.substring(4);
    }
    if (phone.startsWith("01")) {
      return phone.substring(2);
    }

    return phone;
  }
  const navigate = useNavigate();
  useEffect(() => {
    if (user) navigate(-1);
  }, [user, navigate]);
  const classes = useStyles();
  return (
    <div className="grid place-items-center min-h-screen bg-slate-50">
      <Zoom in={true} timeout={1500}>
        <Paper
          elevation={4}
          className="sm:p-4 flex flex-col space-around items-center w-[20rem] sm:w-[30rem] md:w-[40rem] relative !bg-blue-50"
        >
          <img
            src="/images/sudani-logo.jpg"
            alt="logo"
            className={classes.logo}
          />
          <div className="h-16 w-28 mt-4">
            <img
              src="/images/Zool_Cast.png"
              className="block w-full"
              alt="logo"
            />
          </div>

          {error && (
            <p className="text-red-600 mb-4">
              {error?.phone ? error.phone[0] : error}
            </p>
          )}
          <Box p={5} width="100%">
            <Typography
              variant="body2"
              className="!text-xl md:!text-4xl text-center"
            >
              تسجيل الدخول
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                placeholder="01XXXXXXXX"
                value={phone}
                type="tel"
                onChange={(e) => setPhone(e.target.value)}
              />

              <Button
                variant="contained"
                type="submit"
                fullWidth
                className="!bg-[#004D7C] sm:!text-xl md:!py-3   !mt-8"
              >
                تسجيل الدخول
              </Button>

              <a
              href="http://sudanipay.com/subscription/zoalcast/zoalcast/"
                className="text-center m-2 text-[#004D7C] cursor-pointer hover:text-blue-400"
             
              >
             اشترك الآن
              </a>
            </form>
          </Box>
        </Paper>
      </Zoom>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: "grid",
    placeItems: "center",
    height: "100vh",
  },
  card: {
    position: "relative",
    // width: "40rem",
    // height: "30rem",
    padding: "3rem",
    background: "#f1faff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    width: "50rem",
    "& .MuiTypography-body2": {
      fontSize: "2rem",
      textAlign: "center",
      marginTop: "3rem",
    },
  },
  heading: {
    color: "#0076bb",
    textTransform: "uppercase",
    textAlign: "center",
  },
  form: {
    marginTop: "2rem",
    justifyContent: "space-around",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    "& .MuiButtonBase-root": {},
    "& .MuiTextField-root": {
      background: "white",
      width: "100%",
      "&:focus-within": {
        "& fieldset": {
          borderColor: "#004D7C",
          boxShadow: "inset 0 1px 1px #fff,0 0 2px #004D7C",
        },
      },
    },
  },
  logo: {
    position: "absolute",
    top: "0",
    right: "0",
    borderTopRightRadius: "10px",
    width: "3rem",
  },
}));

export default Login;
