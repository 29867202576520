import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getLatestPodcasts } from "../redux/actions/podcastActions";

import PodcastCarousel from "./PodcastCarousel";

const Latest = () => {
  const { loading, podcasts } = useSelector((state) => state.podcastLatest);
  // const [loading, setLoading] = useState(true);

  // let podcasts = podcastsRaw.slice(0, 6);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLatestPodcasts());
  }, [dispatch]);
  return (
    <div className="w-[100%] mx-2 flex flex-col items-center">
      <h1 className="mr-8 text-2xl my-4 text-right w-[75%]">آخر المواضيع</h1>

      <PodcastCarousel
        podcasts={podcasts}
        showLikes
        showViews
        loading={loading}
      />
    </div>
  );
};

export default Latest;
