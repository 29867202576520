import {
  Pause,
  PlayCircleFilled,
  PlayCircleOutline,
} from "@mui/icons-material";
import React, { useEffect, useRef } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { useSelector } from "react-redux";

const PodcastPlayer = ({ podcast }) => {
  const { user } = useSelector((state) => state.loginUser);
  const playerRef = useRef();
  useEffect(() => {
    if (playerRef != null) {
      console.log(playerRef.current.audio.current.pause());
    }
  }, []);
  return (
    <div
      dir="ltr"
      className="w-full sm:w-[90%] md:w-[90%] lg:w-[70%] xl:w-[50%] 2xl:w-[60%] mt-10 mb-4"
    >
      <AudioPlayer
        className="!bg-transparent !text-white child:!text-white w-full "
        autoPlayAfterSrcChange={false}
        ref={playerRef}
        customIcons={{
          play: (
            <PlayCircleFilled className="!text-[40px] !w-[40px] !h-[40px] animate-pulse !" />
          ),
          pause: (
            <Pause className=" !text-[35px] !w-[35px] mt-1 !h-[35px] text-[#919191] " />
          ),
        }}
        // onWaiting={() => setLoading(true)}
        // onPlaying={() => setLoading(false)}
        showFilledVolume
        onError={(e) => console.log(e)}
        src={`https://api.zoalcast.com/api/podcast/${podcast?.id}/sound?Authorization=5084|aJqy2KzOYQhRpy2Y8kfw6IvGUjajcugtU7Quq5Di`}
      />
    </div>
  );
};

export default PodcastPlayer;
