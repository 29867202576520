import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import Sidebar from "./Sidebar";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AUTHENTICATE_USER, OPEN_CANCEL_MODAL } from "../redux/types";
import { logout } from "../redux/actions/authActions";
import { cancelSubscription } from "../redux/actions/subscribtionActions";

const Header = () => {
  const [open, setOpen] = useState(false);

  const { user } = useSelector((state) => state.loginUser);

  const dispatch = useDispatch();
  useEffect(() => {
    document.body.onscroll = () => {
      setOpen(false);
    };
  }, [user]);
  return (
    <>
      <header
        className="flex justify-between items-center p-4  top-0  w-full shadow bg-white"
        onScroll={() => setOpen(false)}
      >
        <div className="flex  items-center">
          <IconButton onClick={() => setOpen(!open)}>
            <MenuIcon />
          </IconButton>
          <Link to="/" className="md:w-34 w-24 ml-4">
            <img
              src="/images/Zool_Cast.png"
              className="block w-full"
              alt="logo"
            />
          </Link>

          <div className="h-8 w-8">
            <img
              src="/images/sudani-logo.jpg"
              className="w-full block rounded-md"
              alt="logo"
            />
          </div>
        </div>

        <div className=" flex">
          {!user ? (
            <>
              {" "}
              <a
                href="http://sudanipay.com/subscription/zoalcast/zoalcast/"
                className=" bg-[#004989] text-center w-full md:w-fit mx-2 text-xs px-2 hover:bg-blue-600  md:text-sm  font-bold  text-white rounded-md py-2 md:px-4"
              >
                اشتراك
              </a>
              <Link
                to="/login"
                className="bg-black w-full text-center md:w-fit  text-xs px-2 hover:bg-blue-600 md:text-sm   font-extrabold  text-white rounded-md py-2 md:px-4"
              >
                دخول
              </Link>
            </>
          ) : (
            <div
              className="font-bold cursor-pointer text-red-400"
              onClick={() => dispatch({ type: OPEN_CANCEL_MODAL })}
            >
              <span>إلغاء الاشتراك</span>
            </div>
          )}
        </div>
      </header>
      <Sidebar open={open} setOpen={setOpen} />
    </>
  );
};

export default Header;
