import {
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Slide,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";

import {
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { AUTHENTICATE_USER, CLOSE_AUTH_MODAL } from "../redux/types";
import { Check, ContentCopy } from "@mui/icons-material";
import CopyToClipboard from "react-copy-to-clipboard";




const ShareModal = ({ podcast, open, setOpen }) => {
  const [openTip, setOpenTip] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCopyLink = async () => {
 
    
      await navigator.clipboard.writeText(window.location.href);
      setOpenTip(true)
 
    }

    function copyToClipboard() {
      const textToCopy = window.location.href
      // navigator clipboard api needs a secure context (https)
      if (navigator.clipboard && window.isSecureContext) {
          // navigator clipboard api method'
          setOpenTip(true)
          return navigator.clipboard.writeText(textToCopy);
         
      } else {
          // text area method
          let textArea = document.createElement("textarea");
          textArea.value = textToCopy;
          // make the textarea out of viewport
          textArea.style.position = "fixed";
          textArea.style.left = "-999999px";
          textArea.style.top = "-999999px";
          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();
          return new Promise((res, rej) => {
              // here the magic happens
              setOpenTip(true)
              document.execCommand('copy') ? res() : rej();
              textArea.remove();
          });
      }
  }
  const handleTooltipClose = () => {
    setOpenTip(false)
  }
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>شارك عبر المنصات الاجتماعية!</DialogTitle>

      <DialogActions className="!flex !justify-between  md:w-[20rem] !p-2 items-center">
        <FacebookShareButton
          quote={`<strong>${podcast?.name}</strong> - ${podcast?.description}`}
          url={window.location.href}
          hashtag="#zoalcast"
        >
          <FacebookIcon borderRadius={6} size={50} />
        </FacebookShareButton>
        <TwitterShareButton url={window.location.href} title={podcast?.name}>
          <TwitterIcon borderRadius={6} size={50} />
        </TwitterShareButton>
        <WhatsappShareButton url={window.location.href} title={podcast?.name}>
          <WhatsappIcon borderRadius={6} size={50} />
        </WhatsappShareButton>
        {/* <TelegramShareButton url={window.location.href} title={podcast?.name}>
          <TelegramIcon borderRadius={6} size={50} />
        </TelegramShareButton> */}
        
    
            <div className="hidden">
              
              
             
                <IconButton className="!bg-slate-200 !rounded-sm  !p-3"  onClick={copyToClipboard}>
              {openTip?  <Check /> : <ContentCopy />}
             
       
        </IconButton>
       
      
          
            </div>
  
      </DialogActions>
    </Dialog>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default ShareModal;


