import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { getMostViewedPodcasts } from "../redux/actions/podcastActions";
import PodcastCarousel from "./PodcastCarousel";
// import Loader from "./Loader";

const MostViewed = () => {
  // const { loading, podcasts } = useSelector((state) => state.podcastMostViewed);

  const { podcasts, loading } = useSelector((state) => state.podcastMostViewed);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMostViewedPodcasts());
  }, [dispatch]);
  return (
    <div className="w-[100%] mx-2 flex flex-col items-center mt-10">
      <h1 className="mr-8 text-2xl my-4 text-right w-[75%]"> الأكثر مشاهدة</h1>

      <PodcastCarousel podcasts={podcasts} showViews={true} loading={loading} />
    </div>
  );
};

export default MostViewed;
