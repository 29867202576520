import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CancelModal from "../components/CancelModal";
import Header from "../components/Header";
import Podcast from "../components/Podcast";
import { GET_RECENT_PLAYS } from "../redux/types";

const RecentsPage = () => {
  const { recentPlays } = useSelector((state) => state.recent);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: GET_RECENT_PLAYS });
  }, []);

  return (
    <div>
      <Header />
      <h2 variant="h2" className="text-2xl  p-6  border-b-2 w-fit">
        تم تشغيلها مؤخرا
      </h2>
      <main className="min-h-screen">
        <div className="justify-items-center gap-y-[4rem] mt-11 grid grid-cols-12">
          {recentPlays?.map((x) => (
            <Podcast podcast={x} showLikes showViews />
          ))}
        </div>
      </main>
      <CancelModal />
    </div>
  );
};

export default RecentsPage;
