import {
  SUBSCRIBE_CANCEL_FAIL,
  SUBSCRIBE_CANCEL_REQUEST,
  SUBSCRIBE_CANCEL_SUCCESS,
  SUBSCRIBE_COMPLETE_FAIL,
  SUBSCRIBE_COMPLETE_REQUEST,
  SUBSCRIBE_COMPLETE_SUCCESS,
  SUBSCRIBE_INITIATE_FAIL,
  SUBSCRIBE_INITIATE_REQUEST,
  SUBSCRIBE_INITIATE_SUCCESS,
  SUBSCRIBE_STATUS_FAIL,
  SUBSCRIBE_STATUS_REQUEST,
  SUBSCRIBE_STATUS_SUCCESS,
} from "../types";

export const subscribeInitiateReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBSCRIBE_INITIATE_REQUEST:
      return { ...state, loading: true };
    case SUBSCRIBE_INITIATE_SUCCESS:
      return { ...state, loading: false, subscribtion: action.payload };
    case SUBSCRIBE_INITIATE_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const subscribeCompleteReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBSCRIBE_COMPLETE_REQUEST:
      return { loading: true };
    case SUBSCRIBE_COMPLETE_SUCCESS:
      return { loading: false, subscribtion: action.payload };
    case SUBSCRIBE_COMPLETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const subscribeStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBSCRIBE_STATUS_REQUEST:
      return { loading: true };
    case SUBSCRIBE_STATUS_SUCCESS:
      return { loading: false, subscription: action.payload };
    case SUBSCRIBE_STATUS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const subscribeCancelReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBSCRIBE_CANCEL_REQUEST:
      return { loading: true };
    case SUBSCRIBE_CANCEL_SUCCESS:
      return { loading: false, subscription: action.payload };
    case SUBSCRIBE_CANCEL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


