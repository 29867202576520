import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { cancelSubscription } from "../redux/actions/subscribtionActions";
import { AUTHENTICATE_USER, CLOSE_CANCEL_MODAL } from "../redux/types";

const CancelModal = () => {
  // const { open } = useSelector((state) => state.AuthModal);
  const { open } = useSelector((state) => state.cancelModal);

  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch({ type: CLOSE_CANCEL_MODAL });
  };

  const handleCancel = () => {
    dispatch(cancelSubscription());
    dispatch({ type: CLOSE_CANCEL_MODAL });
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <DialogContentText
          id="alert-dialog-slide-description"
          className="!text-black"
        >
          هل أنت متأكد انك تريد الغاء الاشتراك؟
        </DialogContentText>
      </DialogContent>
      <DialogActions className="!flex !justify-between">
        <Link
          to="/login"
          onClick={handleCancel}
          className="bg-red-600 mx-2  text-xs w-full font-bold text-center  text-white rounded py-2 px-2"
        >
          الغاء الاشتراك
        </Link>
        <button
          onClick={handleClose}
          className="bg-green-600 mx-2 text-xs w-full font-bold  text-center  text-white rounded py-2 px-2"
        >
          الرجوع
        </button>
      </DialogActions>
    </Dialog>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default CancelModal;
