import { CircularProgress, Pagination } from "@mui/material";
import { ThemeProvider } from "@mui/styles";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import AuthModal from "../components/AuthModal";
import CancelModal from "../components/CancelModal";
import Header from "../components/Header";
import Podcast from "../components/Podcast";
import theme from "../config/theme";

import { getCategoryPodcasts } from "../redux/actions/podcastActions";

const CategoryPage = () => {
  const { category, page } = useParams();
  const { state } = useLocation();

  const { id, background } = state;

  const navigate = useNavigate();
  const handlePageChange = (event, value) => {
    navigate(`/categories/${category}/page=${value}`, {
      state: { id, background },
    });
  };

  const { loading, podcasts, pages } = useSelector(
    (state) => state.categoryPodcasts
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategoryPodcasts(id, page));
  }, [page, id, dispatch]);

  return (
    <div>
      <Header />

      <h2
        style={{ textShadow: "20px 20px 20px #818cf8" }}
        className={` ${background}  p-4 !text-3xl  text-white pr-12  border-b-2 w-full`}
      >
        {category}
      </h2>
      <main className="min-h-screen">
        {loading ? (
          <div className="min-h-screen flex items-center justify-center">
            <CircularProgress />
          </div>
        ) : podcasts.length ? (
          <div className="justify-items-center min-h-full  gap-y-[4rem] mt-11 grid grid-cols-12">
            {podcasts?.map((x) => (
              <Podcast
                key={x.id}
                podcast={x}
                width="w-full"
                height="h-[6rem]"
              />
            ))}
          </div>
        ) : (
          <div className="min-h-screen flex justify-center items-center">
            <p className="text-center text-3xl"> لا يوجد محتوى لهذة الفئة</p>{" "}
          </div>
        )}
      </main>
      <ThemeProvider theme={theme}>
        <div className="col-span-12 grid place-items-center my-6">
          {pages > 1 && (
            <Pagination
              color="primary"
              count={pages}
              page={+page}
              onChange={handlePageChange}
              shape="rounded"
              variant="outlined"
            />
          )}
        </div>
      </ThemeProvider>
      <AuthModal />
      <CancelModal />
    </div>
  );
};

export default CategoryPage;
