import React from "react";

import Podcast from "./Podcast";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { ArrowBackIos } from "@mui/icons-material";
import SliderLoader from "./SliderLoader";

const PodcastCarousel = ({ category, podcasts, loading }) => {
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
  };

  const slides = podcasts?.map((item, i) => (
    <Podcast className="sliderItem" key={i} podcast={item} />
  )).reverse()
  
  console.log({slides})
   return  podcasts != null && podcasts.length > 0 &&  (
    <div className="w-[80%]" >
      {category && (
        <div className="mx-2 md:mx-10 flex justify-between items-center md:p-4">
          <h2 className="md:text-3xl"> {category?.name}</h2>
          <Link to={`/categories/${category?.id}`}>
            عرض الكل
            <ArrowBackIos className="text-red-400 !text-xs font-bold" />
          </Link>
        </div>
      )}

      {loading  ? (
        <SliderLoader />
      ) : (
        <Slider
          {...settings}
     rtl
          lazyLoad
          initialSlide={2}
          autoplay
          responsive={[
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
              },
            },
          ]}
          style={{ display: "inline-block", width: "100%" }}
        >
         {slides}
        </Slider>
      )}
    </div>
  );
};

export default PodcastCarousel;
