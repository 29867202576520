import { Fade, Slide } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import AuthModal from "../components/AuthModal";
import CancelModal from "../components/CancelModal";

import Header from "../components/Header";
import Hero from "../components/Hero";
import Main from "../components/Main";
import Nav from "../components/Nav";
import { getPodcasts } from "../redux/actions/podcastActions";

const Home = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPodcasts());
  }, []);
  return (
    <Slide
      in={true}
      timeout={1000}
      unmountOnExit
      //   addEndListener={() => setTranstion(false)}
    >
      <div>
        <Header />

        <div>
          <Hero />
          <Nav />
          <Main />
          <AuthModal />
          <CancelModal />
        </div>
      </div>
    </Slide>
  );
};

export default Home;
