import { Search } from "@mui/icons-material";
import {
  CircularProgress,
  Pagination,
  Slide,
  ThemeProvider,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AuthModal from "../components/AuthModal";
import CancelModal from "../components/CancelModal";

import Header from "../components/Header";
import Podcast from "../components/Podcast";
import theme from "../config/theme";
// import podcasts from "../data/podcasts";
import { getPodcasts } from "../redux/actions/podcastActions";

const ExplorePodcastPage = () => {
  const [keyword, setKeyword] = useState("");

  // const [page, setPage] = useState(localStorage.getItem("page"));

  const navigate = useNavigate();
  const handlePageChange = (event, value) => {
    // setPage(value);
    navigate(`/explore/page=${value}`);
  };
  const handleSearch = (e) => {
    e.preventDefault();
    dispatch(getPodcasts(keyword));
  };

  const { loading, podcasts, pages } = useSelector(
    (state) => state.podcastsList
  );

  const { page } = useParams();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPodcasts(keyword, page));
  }, [page, keyword, dispatch]);

  return (
    <Slide
      in={true}
      timeout={1000}
      unmountOnExit
      //   addEndListener={() => setTranstion(false)}
    >
      <div>
        <Header />

        <div className="bg-indigo-400 w-full p-10">
          <form
            action=""
            className="bg-white  rounded shadow flex"
            onSubmit={handleSearch}
          >
            <input
              type="search"
              onChange={(e) => setKeyword(e.target.value)}
              placeholder="تصفح البودكاست"
              value={keyword}
              className="focus:outline-none mr-4 flex-1 "
            />
            <button
              type="submit"
              className="px-4 py-2 rounded bg-[#C2D42F] text-white"
            >
              <Search />
            </button>
          </form>
        </div>
        <main className="min-h-screen md:min-h-[70%] ">
          {loading ? (
            <div className="min-h-screen flex items-center justify-center">
              <CircularProgress />
            </div>
          ) : (
            <div className="justify-items-center min-h-full  gap-y-[4rem] mt-11 grid grid-cols-12">
              {podcasts?.map((x) => (
                <Podcast key={x.id} podcast={x} showLikes showViews />
              ))}
            </div>
          )}
        </main>
        <ThemeProvider theme={theme}>
          <div className="col-span-12 grid place-items-center my-6">
            {pages > 1 && (
              <Pagination
                color="primary"
                count={pages}
                page={+page}
                onChange={handlePageChange}
                shape="rounded"
                variant="outlined"
              />
            )}
          </div>
        </ThemeProvider>

        <AuthModal />
        <CancelModal />
      </div>
    </Slide>
  );
};

export default ExplorePodcastPage;
