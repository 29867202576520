import React, { useEffect } from "react";
import {
  Route,
  BrowserRouter,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import Home from "./pages/Home";
import { create } from "jss";
import rtl from "jss-rtl";
import { StylesProvider, jssPreset } from "@mui/styles";

import "./App.css";

import CategoryPage from "./pages/CategoryPage";

import PlaylistPage from "./pages/PlaylistPage";

import LikesPage from "./pages/LikesPage";
import RecentsPage from "./pages/RecentsPage";
import ExplorePodcastPage from "./pages/ExplorePodcastPage";

import ProtectedRoute from "./components/ProtectedRoute";

import Login from "./pages/Login";
import Subscribe from "./pages/Subscribe";

import ScrollTop from "./components/ScrollTop";
import { useDispatch } from "react-redux";
import { checkStatus } from "./redux/actions/subscribtionActions";
import qs from "qs";
import { login, logout } from "./redux/actions/authActions";

// Configure JSS
const jss = create({
  plugins: [...jssPreset().plugins, rtl()],
});

const loader = document.querySelector("#loader");

// if you want to show the loader when React loads data again
// const showLoader = () => loader.classList.remove("loader--hide");

const hideLoader = () => loader.classList.add("hidden");
// const showLoader = () => loader.classList.remove("hidden");
function App() {
  const dispatch = useDispatch();

  window.addEventListener("beforeunload", function (event) {
    // dispatch(logout());
    event.stopImmediatePropagation();
  });

  useEffect(() => {
    hideLoader();
    const { phoneNumber } = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    if (phoneNumber) {
      if (phoneNumber.startsWith("2491")) {
        localStorage.setItem("msisdn", phoneNumber.substring(4));
        dispatch(login({ phone: phoneNumber.substring(4) }));
      } else {
        localStorage.setItem("msisdn", phoneNumber);
        dispatch(login({ phone: phoneNumber }));
      }
    }
    // localStorage.setItem("msisdn", )
    dispatch(checkStatus());
  }, [dispatch]);
  return (
    <div className="App">
      <StylesProvider jss={jss}>
        {/* <ThemeProvider theme={theme}> */}
        <BrowserRouter>
          <ScrollTop>
            <Routes>
              <Route index path="/" element={<Home />} />
              <Route
                path="/categories/:category/page=:page"
                element={<CategoryPage />}
              />
              <Route path="/podcasts/:id" element={<PlaylistPage />} />
              <Route
                path="/likes"
                element={
                  <ProtectedRoute>
                    {" "}
                    <LikesPage />
                  </ProtectedRoute>
                }
              />
              <Route path="/recent" element={<RecentsPage />} />
              <Route
                path="/explore/page=:page"
                element={<ExplorePodcastPage />}
              />
              <Route path="/login" element={<Login />} />
              <Route path="/subscribe" element={<Subscribe />} />
            </Routes>
          </ScrollTop>
        </BrowserRouter>
        {/* </ThemeProvider> */}
      </StylesProvider>
    </div>
  );
}

export default App;
